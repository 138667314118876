<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="!slotLabel ? label : ''"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <template v-id="slotLabel" slot="label">
        <span v-html="label" style="white-space: pre-line"></span>
      </template>
      <b-form-radio-group
        :id="`input-${id}`"
        :name="groupName"
        v-model="inputVal"
        :options="options"
        :state="getValidationState({ dirty, validated, valid, errors })"
        :aria-describedby="`input-${id}-live-feedback`"
        size="md"
        :stacked="stacked"
        :inline="inline"
        @change="onChange"
      ></b-form-radio-group>
      <b-form-invalid-feedback
        :id="`input-${id}-live-feedback`"
        class="d-block"
        >{{ errors[0] }}</b-form-invalid-feedback
      >
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: null,
    vid: String,
    name: String,
    label: String,
    options: Array,
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    slotLabel: {
      type: Boolean,
      default: false,
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
      groupName: `radio-${this._uid}`,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        // console.debug(`inputVal GET: ${JSON.stringify(this.value)}`);
        return this.value;
      },
      set(val) {
        // console.debug(`inputVal SET: ${JSON.stringify(val)}`);
        this.$emit("input", val);
      },
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    onChange(checkedOption) {
      // console.debug("input onChange", checkedOption);
      this.$emit("select", checkedOption);
    },
  },
};
</script>
